import { Link } from 'react-router-dom'
import Input from '../../components/input/Input'
import logo from './logo.svg'
import './Footer.css'

function MinimalFooter() {
  return (
    <footer className='footer footer__minimal'>
      <div className='footer__content'>
        <h4>Vad väntar du på?</h4>
        <p>Lägg till din e-post och bli en av de första att få möjligheten att använda våra tjänster.</p>
        <div className='footer__form'>
          <Input placeholder='E-post:' button='Skriv upp mig!' />
        </div>
        <Link to='/'><img src={logo} alt='capapult logo' className='footer__logo' /></Link>
      </div>
    </footer>
  )
}

export default MinimalFooter