import { Routes, Route } from 'react-router-dom'

import Header from './sections/header/Header'
import Landing from './sections/landing/Landing'
import Party from './sections/party/Party'
import Video from './sections/video/Video'
import Preview from './sections/preview/Preview'
import CapapultApp from './sections/capapultapp/CapapultApp'
import Faq from './sections/faq/Faq'
import Features from './sections/features/Features'
import Social from './sections/social/Social'
import Footer from './sections/footer/Footer'

import Contact from './pages/contact/Contact'
import Press from './pages/contact/Press'

import About from './pages/about/About'
import How from './pages/how/How'

import MinimalFooter from './sections/footer/MinimalFooter'
import Page404 from './pages/404/Page404'

function Website() {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <>
            <div className='landing__container'>
              <Header />
              <Landing />
            </div>
            <Party />
            <Video />
            <Preview />
            <CapapultApp />
            <Faq />
            <Features />
            <Social />
            <Footer />
          </>
        }
      />
      <Route
        path='contact'
        element={
          <>
            <Header black />
            <Contact />
            <Social />
            <Press />
            <MinimalFooter />
          </>
        }
      />
      <Route
        path='about'
        element={
          <>
            <Header black />
            <About />
            <Social />
            <MinimalFooter />
          </>
        }
      />
      <Route
        path='how'
        element={
          <>
            <Header black />
            <How />
            <Social />
            <MinimalFooter />
          </>
        }
      />
            <Route
        path='*'
        element={
          <>
            <Header black />
            <Page404 />
          </>
        }
      />
    </Routes>
  )
}

export default Website
