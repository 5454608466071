import arrowBlack from './arrowBlack.svg'
import arrowWhite from './arrowWhite.svg'
import './FeatureItem.css'

function FeatureItem({ black, title, text, linkTo }) {
  return (
    <div className='feature__item__container'>
      <div className='feature__item__shadow'></div>
      <div
        className='feature__item '
        style={{
          backgroundColor: black ? 'black' : '#F5F5F5',
          color: black ? 'white' : 'black',
        }}
      >
        <div className='feature__item__title'>
          {black && <h4 className='feature__item__title___shadow'>{title}</h4>}
          <h4
            className={
              black
                ? 'feature__item__title___black'
                : 'feature__item__title___white'
            }
          >
            {title}
          </h4>
        </div>
        <p style={{ marginTop: black ? '.5rem' : '-1.6rem' }}>{text}</p>
        {linkTo && (
          <a href={linkTo} style={{ color: black ? 'white' : 'black' }}>
            Läs mer här{' '}
            <img src={black ? arrowWhite : arrowBlack} alt='arrow' />
          </a>
        )}
      </div>
    </div>
  )
}

export default FeatureItem
