import FeatureItem from '../../components/feature/FeatureItem'
import sponsor1 from './sponsors/1.png'
import sponsor2 from './sponsors/2.png'
import sponsor3 from './sponsors/3.png'
import sponsor4 from './sponsors/4.png'
import sponsor5 from './sponsors/5.png'
import './Features.css'

function Features() {
  return (
    <section className='features'>
      <h2>
        PÅ GÅNG <br /> <span>JUST NU!</span>
      </h2>
      <div className='features__container'>
        <div className='features__column'>
          <FeatureItem
            black
            title='11% ränta'
            linkTo='#apy'
            text='Istället för att sätta dina pengar på ett sparkonto med obefintlig ränta kan du genom appen låta dom arbeta för dig i DeFi världen, det är kraften av DeFi.'
          />
          <FeatureItem
            title='Kraftfulla verktyg'
            linkTo='#apy'
            text='Vi arbetar för fullt med att ta fram innovativa verktyg för att underlätta dina vardag. Få översikt av dina inköp, sätt upp sparmål, tips, nyheter osv. direkt i appen.'
          />
        </div>
        <div className='features__column'>
          <FeatureItem
            black
            title='NFT kollektion'
            linkTo='#apy'
            text='Våra 10 000 första användare får en unik NFT när dom registrerar sig i appen, vi kommer snart också kunna erbjuda dig möjligheten att köpa/sälja direkt i appen.'
          />
          <FeatureItem
            title='Gamification'
            linkTo='#apy'
            text='Motorn i spel är otroligt kraftfull och vi arbetar på att ta fram en tjänst där du genom speltekniker kan bli bättre på att spara pengar och förbättra din ekonomi.'
          />
          <FeatureItem
            black
            title='Debet kort'
            linkTo='#apy'
            text='För att göra det enklare för dig kommer vi snart erbjuda ett Debitkort kopplat direkt till appen. Du kan ta del av den höga räntan in i det sista och sticka ut i mängden.'
          />
        </div>
      </div>
      <h3>Vi syns i media</h3>
      <div className='sponsors'>
        <div className='sponsor__container'>
          <img src={sponsor1} alt='sponsor' />
          <img src={sponsor2} alt='sponsor' />
        </div>
        <div className='sponsor__container'>
          <img src={sponsor3} alt='sponsor' />
          <img src={sponsor4} alt='sponsor' />
          <img src={sponsor5} alt='sponsor' />
        </div>
      </div>
    </section>
  )
}

export default Features
