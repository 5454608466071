import { useState } from 'react'
import { Link } from 'react-router-dom'

import './Header.css'
import logo from './logo.svg'
import logoBlack from './logoBlack.svg'
import Button from '../../components/button/Button'

function Header({ black }) {
  const [menu, setMenu] = useState(false)
  return (
    <header className={black ? 'header header__black' : 'header'}>
      <Link to='/'>
        <img
          src={black ? logoBlack : logo}
          className='header__logo'
          alt='Capapult'
          fetchpriority='high'
        />
      </Link>

      <div className='header__links'>
        <Link to='/how'>Så funkar det</Link>
        <Link to='/contact'>Kontakt</Link>
        <Link to='/about'>Om oss</Link>
      </div>
      <Button className='header__button' black={black}>
        Skriv upp mig!
      </Button>
      <div className='header__menu__button' onClick={() => setMenu(true)}>
        <i className='bx bx-menu'></i>
      </div>
      <div
        className='header__menu'
        style={{ display: menu ? 'block' : 'none' }}
      >
        <div className='header__menu__top'>
          <Link to='/'>
            <img
              src={logoBlack}
              className='header__logo header__menu__logo'
              alt='Capapult'
            />
          </Link>
          <div
            className='header__menu__button header__menu__close'
            onClick={() => setMenu(false)}
          >
            <i className='bx bx-x'></i>
          </div>
        </div>
        <div className='header__menu__links'>
          <Link to='/'>Home</Link>
          <Link to='/how'>Så funkar det</Link>
          <Link to='/contact'>Kontakt</Link>
          <Link to='/about'>Om oss</Link>
          <Button black>Skriv upp mig!</Button>
        </div>
      </div>
    </header>
  )
}

export default Header
