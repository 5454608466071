import { useState } from 'react'
import axios from 'axios'
import './Input.css'
import Button from '../button/Button'

function Input({ placeholder, button }) {
  const [value, setValue] = useState('')
  const [done, setDone] = useState(false)
  return (
    <div className='login__container'>
      <input
        type='email'
        placeholder={placeholder}
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
      />
      <Button
        black
        onClick={async () => {
          if (done) return
          setDone(true)
          await axios.post(
            'https://capapult-nft.capapult-se.workers.dev/email',
            JSON.stringify({ email: value })
          )
          setValue('')
        }}
      >
        {done ? 'Sent !' : button}
      </Button>
    </div>
  )
}

export default Input
