import './Landing.css'
import titleSvg from './title.svg'
import mockup from './mockup.png'

import Input from '../../components/input/Input'

function Landing() {
  return (
    <section className='landing'>
      <div className='landing__mockup__container no_mobile'>
        <img
          src={mockup}
          alt='Capapult App Mockup'
          className='landing__mockup'
        />
      </div>
      <div className='landing__title__container'>
        <img src={titleSvg} alt='The power of DeFi' fetchpriority='high' />
        <p className='landing__subtitle'>
          Vi lanserar snart ekonomi-appen <br /> där dina pengar växer med{' '}
          <span>11%</span>
        </p>
        <div className='landing__title__form'>
          <p className='landing__title__text'>
            Skriv upp dig på early access så håller vi kontakten
          </p>
          <Input placeholder='E-post:' button='Skriv upp mig!' />
        </div>
      </div>
    </section>
  )
}

export default Landing
