import Button from '../../components/button/Button'
import video from './video.png'
import './Video.css'

function Video() {
  return (
    <section className='video'>
      <img src={video} alt='Capapult presentation video' className='video__preview'/>
      <p>
        Visste du att dina sparpengar just nu jobbar åt någon annan? Varje minut
        dem finns på ditt konto, är det banken som tjänar på det och inte du.
      </p>
      <p>
        Med hjälp av DeFi och innovativa lösningar skapar vi ett samhälle där DU
        bestämmer över dina pengar och inte tvärtom.
      </p>
      <div className='video__button'>
        <Button>Lär mig mer om DeFi</Button>
      </div>
    </section>
  )
}

export default Video
