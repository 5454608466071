import { useState } from 'react'
import Button from '../../components/button/Button'
import './Faq.css'

const questions = [
  {
    q: 'Hur kan ni erbjuda så bra ränta?',
    a: [
      'Capapult konverterar dina sek till  stablecoin-kryptovalutatillgångar, vilket ger dig tillgång till marknader som vanligtvis bara är inom räckhåll för tekniskt sofistikerade finans- och “datornördar”.',
      'Vi lånar sedan ut dessa stablecoins direkt till låntagare som är villiga att betala hög ränta för den digitala valutan som du tillhandahåller dem. Dessa intäkter kommer tillbaka till dig som SEK i din app.',
      'Detta liknar hur din bank genererar ränta på pengarna på ditt sparkonto idag.',
    ],
  },
  {
    q: 'Varför gör inte alla detta?',
    a: [
      'Egentligen kan vem som helst göra detta i DeFi - det kallas “yield farming”. Men befintliga verktyg är komplexa eller kräver djup teknisk kunskap för att användas på rätt sätt.',
      'Vi gör det enkelt att få hög avkastning i DeFi, precis som det borde vara. ',
    ],
  },
  {
    q: 'Vilka är riskerna?',
    a: [
      'Alla investeringar innebär risk för förluster, marknadsnedgångar och globala krascher.',
      'Du är inte garanterad skydd mot dessa risker men Capapult minimerar dem på flera sätt:',
      'Vi arbetar endast med “översäkrade lån”. Det innebär att låntagare måste ställa ett större belopp än vad de lånar för som säkerhet; dina ursprungliga insättningar kommer aldrig att gå förlorade. Vi samarbetar med de bästa finansiella företagen för att flytta dina pengar på ett säkert sätt till marknadens mest förmånliga priser.',
      'Vi köper och lånar bara ut stablecoins. Dessa valutor följer priset på en normal US-dollar, vilket innebär att de inte har volatiliteten hos populära kryptovalutor som exempelvis Bitcoin. ',
    ],
  },
  {
    q: 'Är min ränta garanterad?',
    a: [
      'När du registrerar dig fixerar vi de räntor som ger dig avkastning på ditt sparande. Räntan är dock inte fixerad för all framtid utan kan komma att justeras upp eller ner beroende på avkastningen från de protokoll som erbjuds.',
    ],
  },
  {
    q: 'Är mina pengar låsta under en period?',
    a: [
      'Nej, du kan fritt ta ut eller sätta in pengar i appen utan hinder. Självklart får du ingen ränta på de pengar du tagit ut från kontot.',
    ],
  },
  {
    q: 'Kostar det något att föra över pengar?',
    a: [
      'Vid överföringar tar DeFi systemet ut en avgift som kallas för Gas. Kostnaden är i nuläget ca. 0,2 kr (20 öre) per överföring.',
    ],
  },
  {
    q: 'Hur tjänar Capapult på detta?',
    a: [
      'Vi tjänar pengar genom att tjäna pengar åt dig. För att få ränta på dina stablecoins kopplar vi ihop dig med säkra lånetagare och hittar de bästa tillgängliga priserna för dina pengar.',
      'När dessa låntagare betalar ränta på sina lån skickar vi tillbaka inkomsten till dig som en belöning för ditt sparande. För att tillhandahålla denna tjänst tar vi en fast procentsats av den intjänade räntan. ',
    ],
  },
]

function Faq({ q, a, bottom }) {
  const [isOpen, setOpen] = useState(false)
  return (
    <div className='faq__question' style={bottom ? { border: 0 } : {}}>
      <h4 onClick={() => setOpen(!isOpen)}>{q}</h4>
      {isOpen && a.map((answr, i) => <p className='faq__answer' key={i}>{answr}</p>)}
    </div>
  )
}

function Faqs() {
  return (
    <section className='faq'>
      <h3>Frågor {'&'} Svar</h3>
      <div className='faq__questions'>
        {questions.map((question, i) => (
          <Faq
            key={i}
            q={question.q}
            a={question.a}
            bottom={i === question.length - 1}
          />
        ))}
      </div>
      <p>Läs mer i vår FAQ eller kontakta oss.</p>
      <Button className='faq__button'>Till FAQ</Button>
    </section>
  )
}

export default Faqs
