import './Social.css'

function Social() {
  return (
    <section className='social'>
      <h3>Häng med oss!</h3>
      <div className='social__icons'>
        <a
          href='https://www.facebook.com/capapult/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='bx bxl-facebook-circle'></i>
        </a>
        <a
          href='https://www.instagram.com/capapult/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='bx bxl-instagram'></i>
        </a>
        <a
          href='https://www.youtube.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='bx bxl-youtube'></i>
        </a>
        <a
          href='https://twitter.com/capapult'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='bx bxl-twitter'></i>
        </a>
      </div>
    </section>
  )
}

export default Social
