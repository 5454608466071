import './Contact.css'
import Button from '../../components/button/Button'

function Contact() {
  return (
    <section className='contact'>
      <h3>Kan vi hjälpa dig med något?</h3>
      <div className='contact__form'>
        <label htmlFor='mail'>What is your email?</label>
        <input type='mail' placeholder='john@doe.com' name='mail' />
        <label htmlFor='text'>What do you want to tell us?</label>
        <textarea name='text' rows={7} />
        <Button black>Send</Button>
      </div>
    </section>
  )
}

export default Contact
