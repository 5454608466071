import mockup from './mockup.png'
import './Preview.css'

function Preview() {
  return (
    <section className='preview'>
      <div className='preview__elements'>
        <div>
          <h3>Du behöver inte ens byta bank</h3>
        </div>
        <div className='preview__element'>
          <div>
            <h4>11% börjar direkt</h4>
            <p>
              Få kontroll över dina pengar och låt dom arbeta för dig i DeFi
              världen.
            </p>
            <h4>Sätt in och ta ut pengar</h4>
            <p>
              Smidigt att sätta in och ta ut pengar via swish eller en
              överföring. - När du vill
            </p>
          </div>
          <div>
            <h4>Hantera dina NFT's</h4>
            <p>
              Spara dina NFT's i appen, supersmidigt och dina DeFi tillgångar är
              samlade på en plats.
            </p>
            <h4>Debit kort</h4>
            <p>
              Självklart ska du ha ett coolt debit kort som sticker ut, enkelt
              att handla direkt från appen.
            </p>
          </div>
        </div>
      </div>
      <div className='preview__container'>
        <img src={mockup} alt='Capapult app mockup' />
        <ul>
          <li>Kraften av DeFi</li>
          <li>Inga dolda avgifter</li>
          <li>Hög avkastning</li>
          <li>Ett steg in i framtiden</li>
        </ul>
      </div>
    </section>
  )
}

export default Preview
