import './About.css'
import video from './body1.mp4'

function About() {
  const marginTop = window.innerHeight - document.querySelector('header').clientHeight
  return (
    <section className='about'>
      <video
        autoPlay
        loop
        muted
        playsInline
        src={video}
        type='video/mp4'
        className='about__video'
      />
      <div className='about__scroll'>
        Rulla ned för att lära dig mer <i class='bx bx-down-arrow-alt' ></i>
      </div>
      <div className='about__container' style={{ marginTop }}>
        <h3>Om oss</h3>
        <p>
          Capapult är ett enkelt och lönsamt sätt att få tillgång till det
          decentraliserade finanssystemet ”DeFi” I appen ser du hur ditt
          sparande växer över tid med 11% och ränta-på-ränta effekten. Du kan
          närsomhelst sätta in eller ta ut pengar från ditt konto.
        </p>
      </div>
    </section>
  )
}

export default About
