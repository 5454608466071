import Button from '../../components/button/Button'
import video from './body1.mp4'
import './Page404.css'

function Page404() {
  return (
    <>
      <video
        autoPlay
        loop
        muted
        playsInline
        src={video}
        type='video/mp4'
        className='page404__video'
      />
      <section className='page404'>
        <h1>404</h1>
        <h3>Page not found</h3>
        <Button to='/' black>
          Go back to the home
        </Button>
      </section>
    </>
  )
}

export default Page404
