import './Title.css'

function Title({ style, black, children }) {
  return (
    <div style={style} className='title'>
      <h1 className='title__shadow'>{children}</h1>
      <h1 className='title__main' style={{ color: black ? 'black' : 'white' }}>
        {children}
      </h1>
    </div>
  )
}

export default Title