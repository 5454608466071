import './How.css'
import video from './body1.mp4'
import Faq from '../../sections/faq/Faq'
import Button from '../../components/button/Button'

function How() {
  const marginTop =
    window.innerHeight - document.querySelector('header').clientHeight
  return (
    <div className='how'>
      <video
        autoPlay
        loop
        muted
        playsInline
        src={video}
        type='video/mp4'
        className='how__video'
      />
      <div className='how__button'>
        <Button black>Lär dig mer om DeFi</Button>
      </div>
      <div className='how__container' style={{ marginTop }}>
        <Faq />

        <section className='how__text'>
          <h3>Det här är DeFi </h3>
          <p>
            DeFi, <a href='#faq'>decentralised finance</a> är ett samlingsnamn
            på de finansiella tjänster som nu i snabb takt byggs kring
            kryptovalutor. Inom det decentraliserade finanssystemet finns ingen
            central aktör, som en bank. Transaktioner sker via{' '}
            <a href='#faq'>smarta kontrakt</a> i en{' '}
            <a href='#faq'>blockkedja</a>. Kort och förenklat innebär det att
            kontrakten bygger på kod som inte kan förändras och ligger i en
            blockkedja. Blockkedjan är transparent, det vill säga alla som vill
            kan se vad den innehåller men utan att användarna identifieras.
          </p>

          <h4>Har du frågor?</h4>
          <Button black style={{ margin: '2rem auto' }} to='/contact'>
            Kontakta oss
          </Button>
        </section>
      </div>
    </div>
  )
}

export default How
