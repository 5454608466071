import { Link } from 'react-router-dom'
import './Button.css'

function Button({ onClick, black, children, style, className, to }) {
  return (
    <div className={(className || '') + ' button__container'} style={style}>
      {to ? (
        <Link
          className='button'
          to={to}
          style={{
            color: black ? 'white' : 'black',
            backgroundColor: black ? 'black' : 'white',
          }}
        >
          {children}
        </Link>
      ) : (
        <button
          className='button'
          onClick={onClick}
          style={{
            color: black ? 'white' : 'black',
            backgroundColor: black ? 'black' : 'white',
          }}
        >
          {children}
        </button>
      )}
      <div className='button__shadow'>{children}</div>
    </div>
  )
}

export default Button
