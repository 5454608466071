import bg from './bg.png'
import text from './text.svg'
import mockup from './mockup.png'
import Button from '../../components/button/Button'

import './CapapultApp.css'

function CapapultApp() {
  return (
    <section className='app'>
      <div className='app__container'>
        <div className='app__top__gradient'></div>
        <img src={bg} alt='background' className='app__bg' />
        <img
          src={text}
          alt='Ta kontroll över din framtida ekonomi!'
          className='app__text'
        />
      </div>
      <div className='app__info__container'>
        <div className='app__container'>
          <img src={mockup} alt='mockup' className='app__mockup' />
        </div>
        <div className='app__info'>
          <h3>Capapult!</h3>
          <p className='bold'>
            Vi bygger på massor av olika smarta lösningar för din ekonomi.
          </p>
          <p>
            Framtiden är här nu och den stavas DeFi. Vi arbetar ständigt med att
            ta fram de tjänster vi anser kan göra stor skillnad för din ekonomi.
          </p>
          <p style={{ marginBottom: '4rem' }}>
            Med ett team av analytiker och experter ser vi till att du får
            tillgång till de senaste teknikerna och kraftfulla funktionerna som
            ska förenkla din vardag och samtidigt ser till att dina pengar kan
            växa. Genom samarbeten med etablerade aktörer i finansvärlden kan vi
            kombinera tryggheten men samtidigt se till att ligga i framkant med
            innovativa lösningar.
          </p>
          <Button black>Berätta mer</Button>
        </div>
      </div>
    </section>
  )
}

export default CapapultApp
