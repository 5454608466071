import Title from '../../components/title/Title'
import bg from './background.jpeg'
import './Party.css'

function Party() {
  return (
    <section className='party'>
      <div>
        <Title>Det är dags att DINA pengar börjar arbeta för DIG!</Title>

        <img src={bg} className='party__bg' alt='Party' />
      </div>
    </section>
  )
}

export default Party
