import { Link } from 'react-router-dom'
import Input from '../../components/input/Input'
import background from './background.png'
import text from './text.svg'
import icon from './icon.svg'
import './Footer.css'

function Footer() {
  return (
    <footer className='footer'>
      <div className='footer__img'>
        <img src={background} alt='background' className='footer__img__bg' />
        <img src={text} alt='text' className='footer__img__txt' />
      </div>
      <div className='footer__content'>
        <img src={icon} alt='capapult' className='footer__icon' />
        <div className='footer__links'>
          <Link to='/how'>Så funkar det</Link>
          <Link to='/path'>Få hjälp</Link>
          <Link to='/contact'>Kontakt</Link>
          <Link to='/about'>Om oss</Link>
        </div>
        <p>Skriv upp dig på early access så håller vi kontakten</p>
        <div className='footer__form'>
          <Input placeholder='E-post:' button='Skriv upp mig!' />
        </div>
        <div className='footer__copy'>
          <h3>Capapult AB</h3>
          <p>
            © Copyright Capapult AB. Redaregatan 48, <br /> 252 36 Helsingborg.
            Org.nr: 559368-2619
          </p>
          <div className='footer__icons'>
            <a
              href='https://www.facebook.com/capapult/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='bx bxl-facebook-circle'></i>
            </a>
            <a
              href='https://www.instagram.com/capapult/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='bx bxl-instagram'></i>
            </a>
            <a
              href='https://www.youtube.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='bx bxl-youtube'></i>
            </a>
            <a
              href='https://twitter.com/capapult'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='bx bxl-twitter'></i>
            </a>
          </div>
          <div className='footer__info'>
            <p>
              Capapult är programvara och verktyg som utvecklats av Capapult AB.
              Capapult är inte en bank och erbjuder inte några bank- eller
              kontotjänster till sina kunder. Vidare omfattas dina insatta medel
              inte av någon statlig insättningsgaranti såsom i en bank. Alla
              investeringar innebär en risk på eget ansvar. Vissa av Capapult
              funktioner som anges är för närvarande under utveckling och är
              inte tillgängliga för alla. Läs mer om våra funktioner och vilka
              risker som finns <Link to='/'>här</Link>.
            </p>
            <p>
              Genom att fortsätta använda webbsidan utan att ändra browser
              inställningar godkänner du att vi använder cookies. Läs mer här.
              När webbsidan med tjänster används behandlar vi dina
              personuppgifter i enlighet med vår <Link to='/'>integritetspolicy</Link>.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
